import React, { Component } from 'react'

// component creates button to reset feed for testing
export class Reset extends Component {
  render() {
    return <div className="reset" >
            <a href="https://bumble-twitter-interview.herokuapp.com/roman-jasko/reset" target="_blank" rel="noopener noreferrer">
                <button>Reset DB</button>
            </a>
        </div>
  }
}

export default Reset