import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { TweetBody } from './components/tweet.js';
import { Message } from './components/message.js';
import { Reset } from './components/reset.js';
import {PullToRefresh, PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";
import './App.css';


ReactGA.initialize('UA-123322241-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA.initialize('UA-123322241-1', {
  debug: true,
  titleCase: false,
  gaOptions: {
    userId: 123,
    gaOptions: { name: 'Twitter Feed page' }
  }
});

class App extends Component {
  constructor(props) {
    super(props)
    // setting the state
    this.state={
      users:
      []
    }
    this.handleRefresh = this.handleRefresh.bind(this)
    this.getUser = this.getUser.bind(this)
  }

  // geting feed with interval 2 seconds
  handleRefresh() {
    return new Promise((resolve) => {
      this.getUser()
      setInterval(this.getUser, 3000);
    });
  }

  // preparing component for the first render
  componentWillMount() {
    this.getUser()
  }

  getUser() {
    fetch('https://bumble-twitter-interview.herokuapp.com/roman-jasko/api?count=X')
    .then(response => {
      if(response.ok) return response.json();
      throw new Error('Request failed');
    })
    .then(data => {
      // console.log(data)
      /*
        Pulling sections with username, image and text from the feed
      */
      this.setState({
        users:[
          {
            name: data[0].username,
            image: data[0].image,
            tweet: data[0].text,
          },
          ...this.state.users,
        ]
      });
    })
    .catch(error => {
      console.log(error);
    });
  }

  render() {
    return (
      <PullToRefresh
      // Pull down feature to initiate feed on UI
      pullDownContent={<PullDownContent />}
      releaseContent={<ReleaseContent />}
      refreshContent={<RefreshContent />}
      pullDownThreshold={3}
      onRefresh={this.handleRefresh}
      triggerHeight={500}
      backgroundColor='#37a0f4'>
       <div>
         <Message /> 
         <Reset />
       </div>
      <div className="main-body">
        {
        // Make cycles with map() to walk through each object  
        [...this.state.users].map((user, index) => {
          let username = user.name
          let image = user.image
          let text = user.tweet
          // console.log(`Roman TEST: ${text}`)
          return(
            <TweetBody 
              key={index}
              name={username}
              tweet={text}
              image={image} 
              />
          )
        })}      
      </div>
      </PullToRefresh>
    );
  }
}

export default App;
